$pink: #ec6173;
$yellow: #fffe79;
$blue: #3867ab;
$lightBlue: #54d3e4;
$orange: #edab40;
$grey: #808080;
$lightGrey: #ccc;
$black: #000000;
$white: #ffffff;
$darkBlue: #2f69bb;

$menuHeight: 54px;
$maxWidth: 1280px;

$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;
$xl: 1600px;

$baseFontSize: 16px;

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body {
  margin: 0;
  font-family: "Archivo", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: -webkit-linear-gradient(
    left,
    $lightBlue,
    $lightBlue 70%,
    $blue 30%,
    $blue
  );
}

.bg-combination-1 {
  min-height: 100vh;
  height: 100%;
  background-image: url(Images/Pattern-Left-01.png),
    url(Images/Pattern-Right-01.png);
  background-position: top 100px left -1800px, right -1000px bottom 0;
  background-repeat: no-repeat, no-repeat;
}

.bg-combination-2 {
  min-height: 100vh;
  height: 100%;
  background-image: url(Images/Pattern-Left-02.png),
    url(Images/Pattern-Right-02.png);
  background-position: top 100px left -1800px, right -1000px bottom 0;
  background-repeat: no-repeat, no-repeat;
}

// Hack for awful bg
.ant-layout {
  background: none;
}

.mainContainer {
  max-width: $maxWidth;
  margin: 0 auto;
  padding-top: $menuHeight;
}

.__LOGO__ {
  .logo {
    position: inherit;
    max-width: 100%;
    height: $menuHeight + 30;
  }
  .logo-mobile {
    position: inherit;
    max-width: 100%;
    height: 64px;
  }
}

@media all and (max-width: 992px) {
  .hide-on-small-screens {
    display: none;
  }
  .__EVENTS__ {
    width: 100vw;
  }
  .event-card {
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
  }
  .event-image {
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }
  .event-social-button {
    width: 100%;
  }
}

@media all and (min-width: 993px) {
  .show-on-small-screens {
    display: none;
  }
  .event-image img {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
  .mainContainer {
    padding: $menuHeight + 50 20px 0 20px;
  }
}

@media all and (min-width: 1201px) {
  .event-image img {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }
  .lazyload-wrapper {
    min-height: 200px;
  }
}

.__HEADER__ {
  .menu-desktop-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2;
    height: $menuHeight;
  }

  .menu-desktop {
    max-width: $maxWidth - 40;
    margin: 0 auto;
  }

  .logoText {
    display: flex;
    padding: 0 20px;
    font-size: 1.2rem;
    background-color: $blue;
    height: $menuHeight;
    color: $white;
    font-weight: bold;
    justify-content: center;
    align-content: center;
    align-items: center;
    a {
      color: $white;
      text-transform: lowercase;
    }
  }

  .change-language {
    background-color: $black;
    height: $menuHeight;
    padding: 0;
    height: $menuHeight;
    text-align: right;
    justify-content: flex-end;

    button {
      text-transform: uppercase;
      padding: 20px;
      font-size: 1.125rem;
      line-height: 100%;
      height: 100%;
      background-color: $black;
      color: $white;
      border: 0;
      outline: none;
      cursor: pointer;

      &.active-language {
        background-color: $white;
        color: $black;
      }
    }
  }

  .download-app {
    background-color: $black;
    height: $menuHeight;
    color: $white;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    p {
      margin: 0;
    }
    a {
      margin-left: 10px;
    }
    .app-store img {
      height: 42px;
    }
    .google-play-store img {
      height: 62px;
    }
  }
}

.__MOBILE_HAMBERGER__ {
  background-color: $black;
  padding: 20px;
  color: $white;

  p {
    margin: 0;
    font-size: 0.9rem;
    text-align: left;
  }
  a {
    margin-left: 10px;
  }
  .download-app-text {
    margin-bottom: 10px;
  }
  .app-store img {
    height: 42px;
    margin-left: -10px;
    width: 125px;
  }
  .google-play-store img {
    height: 62px;
    width: 145px;
    margin-left: -20px;
  }
  .language-section {
    text-align: right;
  }
  .ant-btn {
    background-color: $black;
    color: $white;
    width: 44px;
    border-radius: 0;
    padding: 0;
    height: 44px;
    border: 1px solid $white;
  }
  .ant-btn[disabled] {
    background-color: $white;
    color: $black;
  }
}

.__CREDITS__ {
  h4 {
    color: $white;
    font-weight: bold;
    margin-bottom: 0;
  }
  p {
    color: $white;
    padding: 0;
    margin: 0;
  }
  .credit-section-with-height {
    min-height: 70px;
  }
  .credit-section {
    margin-bottom: 20px;
  }
  .pfl-association {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  a {
    color: $white;
    margin-left: 0;
    text-decoration: underline;
    &:hover {
      color: $white;
    }
  }
}

.__MOBILE_CALENDAR__ {
  background-color: $pink;
  padding: 15px 5px 5px 5px;
  margin: 0 auto;
  text-align: center;
}

.__EVENTS__ {
  .sub-section {
    font-weight: bold;
    margin-bottom: 0;
  }
  .events-navigation {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
}

.__DATE_NAVIGATION__ {
  background-color: $white;
  width: auto;
  margin: 0 auto;
  .currentDate {
    margin: 0;
    text-align: center;
    color: $blue;
    font-size: 20px;
    font-size: $baseFontSize;
    font-weight: bold;
    .ant-btn-text {
      color: $blue;
      font-weight: bold;
    }
  }
}

.event-link {
  display: inherit;
}
.event-link:hover {
  .event-image {
    background-color: #1269c1;
  }
  img {
    opacity: 0.55;
  }
}

.event-card {
  background-color: $white;
  color: $black;
  margin-bottom: 16px;

  .event-image {
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }
  .event-map-link {
    color: $white;
    padding-left: 0;
    font-size: 20px;

    &:hover {
      color: $lightBlue;
    }
  }

  .event-image-detail {
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 300px;
    }
  }
  .event-content {
    h1 {
      font-weight: bold;
    }

    h1,
    h3 {
      color: $black;
      margin-bottom: 0;
      @include text-overflow;
    }
    padding: 10px 15px;
  }
  .event-date {
    text-align: right;
    font-size: 1.15rem;
  }
  .event-detail {
    padding-bottom: 0px;
  }
  .event-social {
    margin-top: 20px;
    text-align: right;
  }

  .event-description-container {
    margin-top: 20px;
  }

  .event-description {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  .content-metadata {
    border-bottom: 1px solid #eee;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }

  .event-social-button {
    margin-bottom: 10px;
  }
  .ant-btn-primary:hover {
    background-color: $darkBlue;
    border-color: $darkBlue;
  }
}

.sidebar {
  .__CALENDAR__ {
    display: flex;
    position: fixed;
    justify-content: flex-end;
  }
}

$calendar-cell: 42px;
.__CALENDAR__ {
  margin-top: 15px;
  .calendar-wrapper {
    background-color: $pink;
    padding: 30px 22px;
    display: inline-block;
  }
  .week {
    display: flex;
  }
  .name {
    background-color: $lightBlue !important;
    color: $blue !important;
    height: 20px !important;
    line-height: 20px;
    font-size: 0.75rem;
  }
  .day {
    background-color: $white;
    color: $lightBlue;
    margin: 2px;
    width: $calendar-cell;
    height: $calendar-cell;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    & .ant-btn-text {
      color: $blue;
      font-weight: bold;
      padding: 0;
      width: $calendar-cell;
      height: $calendar-cell;
    }
  }
  .day-disabled {
    background-color: $blue;
    color: $white;
    & .ant-btn-text {
      color: $white;
    }
  }
  .day-empty {
    background-color: $lightGrey;
  }
  .calendar-navigation {
    margin-top: -45px;
    margin-bottom: 20px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    .currentDate {
      text-transform: capitalize;
    }
  }
}
.__LOADING__ {
  text-align: center;
}

.page {
  background-color: white;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
}

$footer-width: 1240px;
.footer {
  width: $footer-width;
  margin: 0 auto;
}
.footer-wrapper {
  position: fixed;
  bottom: 0;
  right: calc((100vw - 1240px) / 2);
  button {
    background-color: $black;
    color: $white;
    border: 0;
    padding: 10px;
    height: auto;
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    border-radius: 0;
    outline: 0;

    &:hover,
    &:focus {
      background-color: $black;
      color: $white;
    }
  }
}

.footer-content {
  background-color: $black;
  padding: 0 10px;
  width: 366px;
}

.footer-link {
  text-align: right;
}
.footer-link-full {
  width: 366px;
  background-color: $black;
  text-align: right;
  padding-right: 5px;
}
